*,
*::after,
*::before {
   box-sizing: border-box !important;
   text-decoration: none !important;
}

body {
   margin: 0;
   padding: 0;
   border: 0;
   overflow-x: hidden;
}

.app {
   height: 100vh;
   width: 100vw;
   overflow-x: hidden;
}
